<template>
	<div class="exam">
		<div v-if="isList == 'list'">
			<div class="certificateHeader LC_Header">
				待考课程
			</div>
			<div class="courseList" v-if="courseList.length">
				<div class="courseItem" v-for="(item,index) in courseList" :key="index">
					<img :src="item.cou_thumb" class="courseImg" />
					<div class="courseName">{{item.cou_name}}</div>
					<div class="canNotTest" v-if="item.sig_passtext == 'yes'">考试已通过</div>
					<div v-else>
						<div class="AdoptBtn" v-if="item.is_can == 1" @click="startPractice(item)">前往考试</div>
						<div class="canNotTest" v-if="item.is_can == -1 || item.is_can == 0" @click="startPractice(item)">前往考试</div>
					</div>
				</div>
				<div class="coursePaginat" v-if="total > pageSize">
					<el-pagination
					  background
					  layout="prev, pager, next"
					  :total="total"
					  :page-size="pageSize"
					  @current-change="changPage">
					</el-pagination>
				</div>
			</div>
			<div class="noData" v-else>
				<img src="@/views/images/noData.png" />
				<div class="noDataTips">
					<span>暂无数据</span>
				</div>
			</div>
		</div>
		<exam-page v-else-if="isList == 'exam'" :data="cuurItem"></exam-page>
		<!-- 考试规则 -->
		<el-dialog :visible.sync="rulesVisible"
			width="600px" 
			custom-class="rulesDialog"
			:close-on-click-modal="false"
			:close-on-press-escape="false">
			<div class="examRules">
				<div class="examinationRuleTitle">考试前请仔细阅读以下考试规则</div>
				<div class="examinationRuleListBox">
					<div class="examinationRuleList">一、本次考试时间为 <span>{{examInfo.exac_exam_time}}</span> 分钟，从点击“开始考试”按钮后开始计时。</div>
					<div class="examinationRuleList">二、本次考试满分为 <span>{{examInfo.sum}}</span> 分，及格分数为 <span>{{examInfo.exac_pass_score}}</span> 分。</div>
					<div class="examinationRuleList">三、考试共有 <span>{{sig_examinations}}</span> 次机会(考试通过即止)，考试不通过可以选择立即重考。您还剩 <span>{{shengyu}}</span> 次机会，请认真作答。</div>
					<div class="examinationRuleList">四、补考需要缴纳 <span>{{examInfo.exac_again_price}}</span> 费用，在缴纳成功之后可以立即开始补考。</div>
				</div>
				<div class="goExaminationBtn" @click="gotoExam">开始考试</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Bus from '/src/views/js/bus.js'; 
	import examPage from "/src/views/LearningCenter/examPage";//考试页面
	export default {
		components: {
			examPage
		},
		data() {
			return {
				courseList:[],//课程列表
				total:0,
				pageSize:5,
				examInfo:{},//考试信息
				rulesVisible:false,//规则弹窗
				sig_examinations:0,//允许考试次数
				shengyu:0,//剩余考试次数
				cuurItem:{},//当前课程
				isList:"list",//是否为列表
				isHasOrderTypeOne:"",//是否在订单中
				isCarts:0,//是否在购物车中
				courseDetail:{},//课程详情
				are_code:"",//城市code
			}
		},
		methods: {
			//获取考试列表
			getCourseList(page){
				this.$http.post(this.PublicJs.publicPath + "/api/student.Info/waitExam.html", {
					token:window.sessionStorage.getItem('token'),
					page:page,
					limit:this.pageSize,
				}, {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						this.total = response.data.data.list.total;
						this.courseList = response.data.data.list.data;
						this.examInfo = response.data.data.info;
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					if(response.status == 401){
						var that = this;
						this.$message({
							message: "您的账号在别处登录，请重新登录",
							type: 'warning',
							showClose: true
						});
						window.sessionStorage.removeItem('user_id')
						window.sessionStorage.removeItem('token')
						window.sessionStorage.removeItem('userinfo')
						setTimeout(function(){
							that.$router.go(0)
						},2000); 
						
					}
				});
			},
			//前往考试
			startPractice(item){
				if(this.examInfo.exac_isexam){
					if(item.is_can == 1){
						this.$http.post(this.PublicJs.publicPath + "/api/student.Info/paperdataBefore.html", {
							token:window.sessionStorage.getItem('token'),
							course_id:this.PublicJs.Encrypt(item.cou_id),
						}, {
							emulateJSON: true
						}).then(response => {
							if(response.data.code == 1){
								this.rulesVisible = true;
								this.sig_examinations = item.sig_examinations;
								this.shengyu = item.shengyu;
								this.cuurItem = item;
							}else{
								this.$message({
									message: response.data.msg,
									type: 'warning',
									showClose: true
								});
							}
						}, response => {
							if(response.status == 401){
								var that = this;
								this.$message({
									message: "您的账号在别处登录，请重新登录",
									type: 'warning',
									showClose: true
								});
								window.sessionStorage.removeItem('user_id')
								window.sessionStorage.removeItem('token')
								window.sessionStorage.removeItem('userinfo')
								setTimeout(function(){
									that.$router.go(0)
								},2000); 
								
							}
						});
					}else if(item.is_can == -1){
						this.$confirm(item.msg, '提示', {
						  	confirmButtonText: '前往购买',
						  	cancelButtonText: '取消',
						  	type: 'warning'
						}).then(() => {
							//获取课程详情
							this.getCourseDetail(item)
						}).catch(() => {
						           
						});
					}else{
						if(window.localStorage.getItem("paperid")){
							this.$http.post(this.PublicJs.publicPath + "/api/student.Info/paperdataBefore.html", {
								token:window.sessionStorage.getItem('token'),
								course_id:this.PublicJs.Encrypt(item.cou_id),
							}, {
								emulateJSON: true
							}).then(response => {
								if(response.data.code == 1){
									this.rulesVisible = true;
									this.sig_examinations = item.sig_examinations;
									this.shengyu = item.shengyu;
									this.cuurItem = item;
								}else{
									this.$message({
										message: response.data.msg,
										type: 'warning',
										showClose: true
									});
								}
							}, response => {
								if(response.status == 401){
									var that = this;
									this.$message({
										message: "您的账号在别处登录，请重新登录",
										type: 'warning',
										showClose: true
									});
									window.sessionStorage.removeItem('user_id')
									window.sessionStorage.removeItem('token')
									window.sessionStorage.removeItem('userinfo')
									setTimeout(function(){
										that.$router.go(0)
									},2000); 
									
								}
							});
						}else{
							this.$message({
								message: item.msg,
								type: 'warning',
								showClose: true
							});
						}
					}
				}else{
					this.$message({
						message: this.examInfo.exac_closeinfo,
						type: 'warning',
						showClose: true
					});
				}
			},
			//分页
			changPage(val){
				//获取考试列表
				this.getCourseList(val)
			},
			//开始考试
			gotoExam(){
				if(this.shengyu > 0){
					this.rulesVisible = false;
					this.isList = "exam";
				}else{
					this.rulesVisible = false;
					this.$message({
						message: "您考试机会已用完",
						type: 'warning',
						showClose: true
					});
				}
			},
			//获取课程详情
			getCourseDetail(item){
				this.$http.post(this.PublicJs.publicPath + "/api/student.index/courseDetail.html", {
					course_id:this.PublicJs.Encrypt(item.cou_id),
					token:window.sessionStorage.getItem('token'),
					type:1,
					are_code:this.are_code
				}, {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						this.courseDetail = response.data.data.data;
						this.isHasOrderTypeOne = response.data.data.isHasOrderTypeOne;//是否在订单中
						this.isCarts = response.data.data.isCarts;//是否在购物车中
						//立即学习
						this.learnNow(this.PublicJs.Encrypt(item.cou_id))
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					if(response.status == 401){
						var that = this;
						this.$message({
							message: "您的账号在别处登录，请重新登录",
							type: 'warning',
							showClose: true
						});
						window.sessionStorage.removeItem('user_id')
						window.sessionStorage.removeItem('token')
						window.sessionStorage.removeItem('userinfo')
						setTimeout(function(){
							that.$router.go(0)
						},2000); 
						
					}
				});
			},
			//立即学习
			learnNow(cou_id){
				var that = this;
				if(this.isHasOrderTypeOne == ""){
					this.$router.push({
						path: "/confirmOrder",
						query: {
							course_id:cou_id,
							isCarts:this.PublicJs.Encrypt(this.isCarts),
							isMakeup:this.PublicJs.Encrypt("true"),
						}
					});
				}else{
					this.$message({
						message: "该课程已在订单中，请前往订单中支付",
						type: 'warning',
						showClose: true
					});
					setTimeout(function(){
						that.$router.push({
							path: "/order"
						});
					},1500);
				}		
			},
		},
		created() {
			Bus.$emit('bus_menuChose', "exam");
			if(this.$cookies.get("are_code")){
				this.are_code = this.$cookies.get("are_code")
			}
			this.PublicJs.isExam = false;
			//获取考试列表
			this.getCourseList(1)			
		}
	}
</script>

<style scoped="scoped">
	.noData{
		text-align: center;
		width: 100%;
		height: 236px;
		margin-top: 153px;
	}
	.noDataTips{
		font-size: 16px;
		color: #333;
		margin-top: 25px;
	}
	.cBlue a{
		color: #4d6ef2;
	}
	.cBlue a:hover{
		color: #1d48d2;
	}
	.courseList{
		padding: 0 35px;
	}
	.courseItem{
		width: 100%;
		height: 115px;
		padding: 20px 0;
		border-bottom: 1px solid #E2E2E2;
		line-height: 75px;
		font-size: 0;
		position: relative;
		padding-right: 118px;
	}
	.courseItem:last-child{
		border-bottom: none;
	}
	.courseImg{
		width: 110px;
		height: 75px;
		margin-right: 10px;
		border-radius: 3px;
		vertical-align: middle;
	}
	.courseName{
		display: inline-block;
		vertical-align: middle;
		font-size: 14px;
		color: #333;
	}
	.courseBtn{
		width: 90px;
		height: 30px;
		border: 1px solid #e2e2e2;
		text-align: center;
		line-height: 30px;
		cursor: pointer;
		border-radius: 3px;
		position: absolute;
		right: 0;
		top: 50%;
		margin-top: -15px;
		font-size: 14px;
	}
	.courseBtn:hover{
		border: 1px solid #2B54A9;
		color: #2B54A9;
	}
	.AdoptBtn{
		width: 90px;
		height: 30px;
		border: 1px solid #2B54A9;
		text-align: center;
		line-height: 28px;
		cursor: pointer;
		background: #2B54A9;
		border-radius: 3px;
		color: #FFF;
		position: absolute;
		right: 0;
		top: 50%;
		margin-top: -15px;
		font-size: 14px;
	}
	.canNotTest{
		width: 90px;
		height: 30px;
		border: 1px solid #e2e2e2;
		text-align: center;
		line-height: 28px;
		cursor: no-drop;
		background: #efefef;
		border-radius: 3px;
		color: #999999;
		position: absolute;
		right: 0;
		top: 50%;
		margin-top: -15px;
		font-size: 14px;
	}
	.examRules{
		text-align: center;
	}
	.examinationRuleTitle{
		font-size: 18px;
		color: #ff771e;
		line-height: 30px;
	}
	.examinationRuleList {
	    line-height: 26px;
	    font-size: 14px;
	    color: #101e18;
	    margin-top: 15px;
		text-align: left;
	}
	.examinationRuleList span {
	    color: #000000;
	    font-weight: bold;
	}
	.goExaminationBtn {
	    width: 90px;
	    height: 35px;
	    line-height: 35px;
	    font-size: 16px;
	    color: #ffffff;
	    background-color: #2B54A9;
	    cursor: pointer;
	    border-radius: 3px;
	    margin: 45px auto 0px;
	}
	.coursePaginat{
		text-align: center;
		margin-top: 20px;
	}
</style>
<style>
	.rulesDialog .el-dialog__header{
		padding: 0;
	}
</style>
