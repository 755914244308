<template>
	<div class="examPage" v-loading="examPageloading">
		<div class="certificateHeader LC_Header">
			当前考试（课程名称：{{data.cou_name}}）
		</div>
		<div class="subjectInfo">
			<div class="timeTitlePath">
				<img class="clockIcon" src="@/views/images/clockIcon.png" />
				<span class="timeVal">{{timeVal}}</span>
			</div>
			<div class="subjectHeader">
				<div class="subjectType">{{cuurSubject.type_text}}</div>
				<div class="subjectIndex">
					<span>第</span>
					<span class="indexColor">{{cuurIndex}}</span>
					<span>/{{subjectData.length}}</span>
				</div>
				<span v-html="cuurSubject.name_text"></span>
				<!--<div class="subjectTitle">{{cuurSubject.name_text}}</div>-->
			</div>
			<div class="optionList">
				<ul v-if="cuurSubject.type_text == '多选题'">
					<li v-if="cuurSubject.que_optiona" class="multipleItem" @click="clickMultiple('A')" :class="{cuurChose:PublicJs.ArrayIndexOf('A',cuurChose)>-1}">
						<div class="circular"><span class="csolid"></span></div>
						<span>A</span>
						<span class="optiontit">{{cuurSubject.que_optiona}}</span>
					</li>
					<li v-if="cuurSubject.que_optionb" class="multipleItem" @click="clickMultiple('B')" :class="{cuurChose:PublicJs.ArrayIndexOf('B',cuurChose)>-1}">
						<div class="circular"><span class="csolid"></span></div>
						<span>B</span>
						<span class="optiontit">{{cuurSubject.que_optionb}}</span>
					</li>
					<li v-if="cuurSubject.que_optionc" class="multipleItem" @click="clickMultiple('C')" :class="{cuurChose:PublicJs.ArrayIndexOf('C',cuurChose)>-1}">
						<div class="circular"><span class="csolid"></span></div>
						<span>C</span>
						<span class="optiontit">{{cuurSubject.que_optionc}}</span>
					</li>
					<li v-if="cuurSubject.que_optiond" class="multipleItem" @click="clickMultiple('D')" :class="{cuurChose:PublicJs.ArrayIndexOf('D',cuurChose)>-1}">
						<div class="circular"><span class="csolid"></span></div>
						<span>D</span>
						<span class="optiontit">{{cuurSubject.que_optiond}}</span>
					</li>
					<li v-if="cuurSubject.que_optione" class="multipleItem" @click="clickMultiple('E')" :class="{cuurChose:PublicJs.ArrayIndexOf('E',cuurChose)>-1}">
						<div class="circular"><span class="csolid"></span></div>
						<span>E</span>
						<span class="optiontit">{{cuurSubject.que_optione}}</span>
					</li>
					<li v-if="cuurSubject.que_optionf" class="multipleItem" @click="clickMultiple('F')" :class="{cuurChose:PublicJs.ArrayIndexOf('F',cuurChose)>-1}">
						<div class="circular"><span class="csolid"></span></div>
						<span>F</span>
						<span class="optiontit">{{cuurSubject.que_optionf}}</span>
					</li>
				</ul>
				<ul v-else>
					<li v-if="cuurSubject.que_optiona" class="optionItem" @click="clickOption('A')" :class="{checked:checked == 'A'}">
						<div class="circular"><span class="csolid"></span></div>
						<span>A</span>
						<span class="optiontit">{{cuurSubject.que_optiona}}</span>
					</li>
					<li v-if="cuurSubject.que_optionb" class="optionItem" @click="clickOption('B')" :class="{checked:checked == 'B'}">
						<div class="circular"><span class="csolid"></span></div>
						<span>B</span>
						<span class="optiontit">{{cuurSubject.que_optionb}}</span>
					</li>
					<li v-if="cuurSubject.que_optionc" class="optionItem" @click="clickOption('C')" :class="{checked:checked == 'C'}">
						<div class="circular"><span class="csolid"></span></div>
						<span>C</span>
						<span class="optiontit">{{cuurSubject.que_optionc}}</span>
					</li>
					<li v-if="cuurSubject.que_optiond" class="optionItem" @click="clickOption('D')" :class="{checked:checked == 'D'}">
						<div class="circular"><span class="csolid"></span></div>
						<span>D</span>
						<span class="optiontit">{{cuurSubject.que_optiond}}</span>
					</li>
					<li v-if="cuurSubject.que_optione" class="optionItem" @click="clickOption('E')" :class="{checked:checked == 'E'}">
						<div class="circular"><span class="csolid"></span></div>
						<span>E</span>
						<span class="optiontit">{{cuurSubject.que_optione}}</span>
					</li>
					<li v-if="cuurSubject.que_optionf" class="optionItem" @click="clickOption('F')" :class="{checked:checked == 'F'}">
						<div class="circular"><span class="csolid"></span></div>
						<span>F</span>
						<span class="optiontit">{{cuurSubject.que_optionf}}</span>
					</li>
				</ul>
				<div class="answerList">
					<div v-if="cuurSubject.isAnswer">
						<div class="userAnswerPath">已选答案： {{cuurSubject.choseAnswer}}</div>
					</div>
				</div>
			</div>
			<div class="stepBtnPath">
				<span class="btnList" :class="{noclick:cuurIndex == 1}" @click="prevSubject">上一题</span>
				<span class="btnList" :class="{noclick:cuurIndex == subjectData.length}" @click="nextSubject">下一题</span>
				<span class="showThis" @click="isShowClick">隐藏题卡</span>
				<span class="submissionMockExam" v-loading.fullscreen.lock="loading" @click="handPapers">交卷</span>
			</div>
			<div class="questionsNumberPath" v-if="isShow">
				<ul>
					<li class="numItem" v-for="(item,index) in subjectData" :key="index" 
					:class="[{numChecked:item.choseAnswer},{numNow: (index+1) == cuurIndex}]" @click="clicknumItem(index)">
						{{index + 1}}
					</li>
				</ul>
			</div>
		</div>
		<!-- 成绩弹窗 -->
		<el-dialog :visible.sync="scoreVisible" 
			width="350px" 
			custom-class="scoreDialog"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			:show-close="false">
			<scorebox :examData="examData" @close-score="closeScore" @once-more="onceMore" @answer-sheet="answerSheet"></scorebox>
		</el-dialog>
		<!-- 答卷弹窗 -->
		<el-dialog :visible.sync="answerVisible"
			width="900px" 
			custom-class="answerDialog"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			:show-close="false">
			<answer-sheet :couData="couData" :studentExam="studentExam" :answerList="answerList" @goback-list="gobackList"></answer-sheet>
		</el-dialog>
	</div>
</template>

<script>
	import scorebox from "/src/components/scorebox";//交卷后弹窗
	import answerSheet from "/src/components/answerSheet";//答卷弹窗
	import { Loading } from 'element-ui';
	export default {
		components: {
			scorebox,
			answerSheet
		},
		props:{
			data:{
				type: Object
			}
		},
		data() {
			return {
				loading:false,
				subjectData:[],//题目数据
				cuurSubject:{},//当前题目
				cuurIndex:1,//当前题号
				exac_exam_time:0,//考试时长
				socres:{},//分数
				timeVal:"",//倒计时
				cuurChose:[],//多选选中
				checked:"",//单选选中
				isShow:true,//是否显示答题卡
				timeId:null,//倒计时
				scoreVisible:false,//成绩弹窗
				score:0,//分数
				answerVisible:false,//答卷弹窗
				yida:0,//已答
				examData:{},//提交后信息
				stex_id:"",//提交后答卷id
				studentExam:{},//答题信息
				answerList:[],//答卷列表
				examPageloading:true,
				couData:{}
			}
		},
		methods:{
			//获取模拟考试题目
			getmockExam(){
				this.$http.post(this.PublicJs.publicPath + "/api/student.Info/paperdata.html", {
					token:window.sessionStorage.getItem('token'),
					course_id:this.PublicJs.Encrypt(this.data.cou_id)
				}, {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						this.examPageloading = false;
						this.subjectData = response.data.data.data;
						for(var i = 0;i<this.subjectData.length;i++){
							this.subjectData[i].isAnswer = false;
							this.subjectData[i].choseAnswer = ""
						}
						this.exac_exam_time = response.data.data.times * 60 * 1000;
						window.sessionStorage.setItem("subjectData",this.PublicJs.Encrypt(JSON.stringify(this.subjectData)))
						this.cuurSubject = this.subjectData[0];
						//默认选中
						this.defaultSubjectInfo()
						console.log(this.subjectData)
						//倒计时
						this.TimeDown();
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					if(response.status == 401){
						var that = this;
						this.$message({
							message: "您的账号在别处登录，请重新登录",
							type: 'warning',
							showClose: true
						});
						window.sessionStorage.removeItem('user_id')
						window.sessionStorage.removeItem('token')
						window.sessionStorage.removeItem('userinfo')
						setTimeout(function(){
							that.$router.go(0)
						},2000); 
						
					}
				});
			},
			//点击选项(单选)
			clickOption(val){
				this.checked = val
				this.cuurSubject.isAnswer = true;
				this.cuurSubject.choseAnswer = val;
				this.subjectData[this.cuurIndex-1].isAnswer = true;
				this.subjectData[this.cuurIndex-1].choseAnswer = val
			},
			//上一题
			prevSubject(){
				if(this.cuurSubject.type_text == "多选题"){
					//多选确定
					this.MultipleOk()
				}
				this.checked = "";
				this.cuurChose = [];
				if(this.cuurIndex > 1){
					this.cuurSubject = this.subjectData[this.cuurIndex -2];
					this.cuurIndex = this.cuurIndex - 1;
				}
				//默认选中
				this.defaultSubjectInfo()
			},
			//下一题
			nextSubject(){
				if(this.cuurSubject.type_text == "多选题"){
					//多选确定
					this.MultipleOk()
				}
				this.checked = "";
				this.cuurChose = [];
				if(this.cuurIndex < this.subjectData.length){
					this.cuurSubject = this.subjectData[this.cuurIndex];
					this.cuurIndex = this.cuurIndex + 1;
				}
				//默认选中
				this.defaultSubjectInfo()
			},
			//点击显示隐藏答题卡
			isShowClick(){
				this.isShow = !this.isShow;
			},
			//点击题号
			clicknumItem(val){
				if(this.cuurSubject.type_text == "多选题"){
					//多选确定
					this.MultipleOk()
				}
				this.checked = "";
				this.cuurChose = [];
				this.cuurSubject = this.subjectData[val];
				this.cuurIndex = val + 1;
				//默认选中
				this.defaultSubjectInfo()
			},
			//默认选中
			defaultSubjectInfo(){
				if(this.cuurSubject.isAnswer){
					if(this.cuurSubject.type_text == "多选题"){
						this.cuurChose = this.cuurSubject.choseAnswer.split(",");
					}else{
						this.checked = this.cuurSubject.choseAnswer;
					}
				}
				window.sessionStorage.setItem("subjectData",this.PublicJs.Encrypt(JSON.stringify(this.subjectData)))
			},
			//点击多选选项
			clickMultiple(val){
				if(this.PublicJs.ArrayIndexOf(val,this.cuurChose) > -1){
					this.PublicJs.ArrayRemove(val,this.cuurChose) 
				}else{
					this.cuurChose.push(val);
				}
			},
			//多选确定
			MultipleOk(){
				if(this.cuurChose.length){
					var optionArr = ["A","B","C","D","E","F"];
					var finalArr = []
					for(var i=0;i<optionArr.length;i++){
						for(var j=0;j<this.cuurChose.length;j++){
							if(optionArr[i] == this.cuurChose[j]){
								finalArr.push(optionArr[i])
							}
						}
					}
					this.cuurSubject.isAnswer = true;
					this.cuurSubject.choseAnswer = finalArr.join(",");
					this.subjectData[this.cuurIndex-1].isAnswer = true;
					this.subjectData[this.cuurIndex-1].choseAnswer = finalArr.join(",");
				}				
			},
			//倒计时
			TimeDown() {
				var that = this;
				//倒计时的总秒数
				var totalSeconds = parseInt(this.exac_exam_time / 1000);
				//取模（余数）
				var modulo = totalSeconds % (60 * 60 * 24);
				//小时数
				var hours = Math.floor(modulo / (60 * 60));
				modulo = modulo % (60 * 60);
				//分钟
				var minutes = Math.floor(modulo / 60);
				//秒
				var seconds = modulo % 60;
				hours = hours.toString().length == 1 ? '0' + hours : hours;
				minutes = minutes.toString().length == 1 ? '0' + minutes : minutes;
				seconds = seconds.toString().length == 1 ? '0' + seconds : seconds;
				//输出到页面
				this.timeVal =  hours + ":" + minutes + ":" + seconds;
				//延迟一秒执行自己
				if (hours == "00" && minutes == "00" && parseInt(seconds) - 1 < 0) {
					//确定交卷
					this.handPapersOk()
				} else {
					this.timeId = setTimeout(function () {
						that.exac_exam_time = that.exac_exam_time - 1000;
						that.TimeDown();
					}, 1000)
				}
			},
			//显示用时
			totalSeconds(val){
				//取模（余数）
				var modulo = val % (60 * 60 * 24);
				//小时数
				var hours = Math.floor(modulo / (60 * 60));
				modulo = modulo % (60 * 60);
				//分钟
				var minutes = Math.floor(modulo / 60);
				//秒
				var seconds = modulo % 60;
				hours = hours.toString().length == 1 ? '0' + hours : hours;
				minutes = minutes.toString().length == 1 ? '0' + minutes : minutes;
				seconds = seconds.toString().length == 1 ? '0' + seconds : seconds;
				//输出到页面
				return  hours + ":" + minutes + ":" + seconds;
			},
			//交卷
			handPapers(){
				var that = this;
				this.yida = 0;
				for(var i=0;i<this.subjectData.length;i++){
					if(this.subjectData[i].isAnswer){
						this.yida ++ ;
					}
				}
				clearTimeout(this.timeId)
				this.timeId = null;
				if(this.exac_exam_time > 0 && this.yida == 0){
					this.$confirm('您还未作答，确定要交卷吗?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'}).then(() => {
							//确定交卷
							this.handPapersOk()
						}).catch(() => {
							this.timeId = setTimeout(function () {
								that.exac_exam_time = that.exac_exam_time - 1000;
								that.TimeDown();
							}, 1000)
					});
				}else{
					//确定交卷
					this.handPapersOk()
				}
			},
			//确定交卷
			handPapersOk(){
				var that = this;
				if(this.cuurSubject.type_text == "多选题"){
					//多选确定
					this.MultipleOk()
				}
				var answerdb = {}
				this.yida = 0;
				for(var i=0;i<this.subjectData.length;i++){
					answerdb[this.subjectData[i].que_id] = this.subjectData[i].choseAnswer;
					if(this.subjectData[i].isAnswer){
						this.yida ++ ;
					}
				}
				this.loading=true;
				this.$http.post(this.PublicJs.publicPath + "/api/student.Info/submitExam.html", {
					token:window.sessionStorage.getItem('token'),
					course_id:this.PublicJs.Encrypt(this.data.cou_id),
					answerdb:answerdb
				}, {
					emulateJSON: true
				}).then(response => {
					this.loading=false;
					if(response.data.code == 1){
						this.scoreVisible = true;
						this.examData = response.data.data.examData;
						this.stex_id = response.data.data.stex_id;
						this.answerList = response.data.data.records;
						this.studentExam = response.data.data.examData;
						this.couData = response.data.data.courseInfo;
						window.sessionStorage.removeItem("subjectData")
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
						setTimeout(()=>{
							that.$router.go(0);
						},3000)
					}
				}, response => {
					if(response.status == 401){
						var that = this;
						this.$message({
							message: "您的账号在别处登录，请重新登录",
							type: 'warning',
							showClose: true
						});
						window.sessionStorage.removeItem('user_id')
						window.sessionStorage.removeItem('token')
						window.sessionStorage.removeItem('userinfo')
						setTimeout(function(){
							that.$router.go(0)
						},2000); 
						
					}
				});
			},
			//关闭按钮
			closeScore(){
				this.$router.go(0)
			},
			//再来一次
			onceMore(){
				var that = this;
				if(this.examData.shengyu > 0){
					this.scoreVisible = false;
					//获取模拟考试题目
					this.getmockExam()
				}else{
					this.$message({
						message: "您已没有考试次数",
						type: 'warning',
						showClose: true
					});
					setTimeout(function(){
						that.$router.go(0)
					},500);
				}
			},
			//查看答卷
			answerSheet(){
				this.scoreVisible = false;
				this.answerVisible = true;
			},
			//返回列表
			gobackList(){
				this.$router.go(0)
			},
		},
		created() {
			//获取模拟考试题目
			this.getmockExam()
			this.PublicJs.isExam = true;
			//禁用F12
			document.onkeydown = function() {
				var e = window.event || arguments[0];
				if (e.keyCode == 123) {
					return false;
				}
			}
		}
	}
</script>

<style scoped="scoped">
	.subjectInfo{
		padding: 35px;
	}
	.timeTitlePath{
		width: 125px;
		height: 48px;
		position: relative;
		line-height: 48px;
		font-size: 0;
		margin: 0 auto;
	}
	.clockIcon{
		vertical-align: middle;
	}
	.timeVal{
		position: absolute;
		left: 40px;
		top: 0px;
		font-size: 16px;
		color: #666666;
	}
	.stopTimeBtn{
		position: absolute;
		right: 0;
		top: 0;
		font-size: 14px;
		color: #666666;
		cursor: pointer;
	}
	.subjectHeader{
		font-size: 14px;
		color: #101e18;
		line-height: 25px;
		margin-top: 20px;
	}
	/*.subjectHeader::after{
		content: "";
		display: block;
		clear: both;
	}*/
	.subjectType{
		float: left;
		height: 25px;
		padding: 0px 7px;
		background-color: #2B54A9;
		font-size: 12px;
		color: #ffffff;
		border-radius: 3px;
		line-height: 25px;
		margin-right: 10px;
	}
	.subjectIndex{
		font-size: 12px;
		color: #999999;
		line-height: 25px;
		float: left;
		margin-right: 10px;
	}
	.subjectIndex .indexColor{
		color: #ff771e;
	}
	.subjectTitle{
		font-size: 14px;
		color: #101e18;
		line-height: 25px;
		float: left;
	}
	.optionList{
		padding: 21px 25px 25px;
		border-bottom: 1px solid #e2e2e2;
		position: relative;
	}
	.optionItem{
		height: 16px;
		line-height: 16px;
		margin-bottom: 19px;
		font-size: 14px;
		color: #101e18;
		cursor: pointer;
	}
	.optionItem::after,
	.answerList::after{
		content: "";
		clear: both;
		display: block;
	}
	.circular{
		width: 16px;
		height: 16px;
		border-radius: 8px;
		border: 1px solid #E2E2E2;
		float: left;
		margin-right: 10px;
		text-align: center;
		line-height: 16px;
	}
	.csolid{
		width: 12px;
		height: 12px;
		border-radius: 6px;
		display: inline-block;
	}
	.optiontit{
		margin-left: 10px;
	}
	.optionItem:hover{
		color: #2B54A9;
	}
	.checked{
		color: #5EC513;
	}
	.checked .circular{
		border: 1px solid #5EC513;
	}
	.checked .csolid{
		background-color: #5EC513;
	}
	.answerList{
		height: 20px;
		font-size: 14px;
		color: #101e18;
	}
	.okBtnStyle{
		position: absolute;
		width: 90px;
		height: 35px;
		background-color: #2B54A9;
		color: #ffffff;
		cursor: pointer;
		border-radius: 3px;
		text-align: center;
		line-height: 35px;
		font-size: 16px;
		right: 25px;
		bottom: 25px;
	}
	.okBtnStyle:hover{
		opacity: 0.8;
	}
	.stepBtnPath{
		margin-top: 20px;
		position: relative;
	}
	.btnList{
		display: inline-block;
		background-color: #2B54A9;
		color: #ffffff;
		cursor: pointer;
		width: 90px;
		height: 35px;
		border-radius: 3px;
		text-align: center;
		line-height: 35px;
		font-size: 16px;
		margin-right: 10px;
	}
	.btnList:hover{
		opacity: 0.8;
	}
	.btnList.noclick{
		background-color: #efefef;
		color: #666666;
		cursor: no-drop;
	}
	.showThis{
		float: right;
		cursor: pointer;
		font-size: 14px;
		color: #666666;
	}
	.showThis:hover{
		color: #40AFFE;
	}
	.questionsNumberPath{
		margin-top: 40px;
		max-height: 320px;
		overflow: auto;
	}
	.questionsNumberPath::after{
		content: "";
		display: block;
		clear: both;
	}
	.numItem{
		float: left;
		width: 40px;
		height: 40px;
		text-align: center;
		line-height: 40px;
		cursor: pointer;
		margin: 0px 3px 6px 0px;
		box-sizing: border-box;
		border-radius: 3px;
		font-size: 14px;
		background-color: #ffffff;
		border: 1px solid #e2e2e2;
		color: #666666;
	}
	.numItem.numChecked{
		background-color: #5ec513;
		border: 1px solid #5ec513;
		color: #ffffff;
	}
	.numItem:hover,
	.numItem.numNow{
		border: 1px solid #2B54A9;
	}
	.multipleItem{
		height: 16px;
		line-height: 16px;
		margin-bottom: 19px;
		font-size: 14px;
		color: #101e18;
		cursor: pointer;
	}
	.multipleItem .circular{
		border-radius: 3px;
	}
	.multipleItem .csolid{
		border-radius: 2px;
	}
	.multipleItem.cuurChose{
		color: #5ec513;
	}
	.multipleItem.cuurChose .circular{
		border: 1px solid #5ec513;
	}
	.multipleItem.cuurChose .csolid{
		background-color: #5ec513;
	}
	.submissionMockExam{
		display: block;
		width: 90px;
		height: 35px;
		font-size: 16px;
		text-align: center;
		line-height: 35px;
		border-radius: 3px;
		color: #ffffff;
		cursor: pointer;
		background: -webkit-linear-gradient(left, #ff9f49, #ff771e);
		background: -o-linear-gradient(right, #ff9f49, #ff771e);
		background: -moz-linear-gradient(right, #ff9f49, #ff771e);
		background: linear-gradient(to right, #ff9f49, #ff771e);
		-moz-box-shadow: 3px 3px 3px #ffdfca;
		-webkit-box-shadow: 3px 3px 3px #ffdfca;
		box-shadow: 3px 3px 3px #ffdfca;
		position: absolute;
		bottom: 0;
		left: 50%;
		margin-left: -45px;
	}
	.submissionMockExam:hover{
		background: -webkit-linear-gradient(left, #ff771e, #ff9f49);
		background: -o-linear-gradient(right, #ff771e, #ff9f49);
		background: -moz-linear-gradient(right, #ff771e, #ff9f49);
		background: linear-gradient(to right, #ff771e, #ff9f49);
	}
</style>
